<script>
import Tabulation from "@/view/tabulate/Tabulate.vue";
import SearchCombination from "@/view/search/SearchCombination.vue";
export default {
  components: {SearchCombination, Tabulation},
  data(){
    return{
      tableData: null,
      pageSize: 20,
      pageNum: 1,
      total: 0,
      search: ""
    }
  },
  created() {
    this.getBlackList()
  },
  methods: {
    /**
     * 获取黑名单列表
     */
    getBlackList(data = null, mod = null){
      this.axios.get(this.$api.publicUrl.BlackList, {
        params: {
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          ...data
        }
      }).then(res =>{
        if(res.data.status === 200){
          this.tableData = res.data.data.list
          this.total = res.data.data.total
          if(mod){
            this.$message.success("查询成功！")
          }
        }else {
          this.$message(res.data.data.message)
        }
      })
    },
    /**
     * 1、创建模板数据； 2、调用创建弹出层的方法
     * @param ti  {string}  标题
     * @param mod {string}  方式；例如 添加、修改
     * @param data  {Object}  源数据； 最终弹出层的回调事件会将修改后的源数据返回，以便于后续提交表单数据
     */
    createTemplateData(ti, mod, data = null){
      this.$createPopup("form",{
        ti: ti,
        tree: [
          [{label: "昵称", type: "text", key: "nickname", prop: "nickname"}, {label: "姓名", type: "text", key: "userName", prop: "userName"}],
          [{label: "电话", type: "number", key: "phoneNumber", prop: "phoneNumber"}, {label: "来源", type: "select", key: "userSource", prop: "userSource",
            options: [
              {value: '哈啰', label: '哈啰'},
              {value: '携程', label: '携程'},
              {value: '飞猪', label: '飞猪'},
              {value: '租租车', label: '租租车'},
              {value: '同行', label: '同行'},
              {value: '线下', label: '线下'},
            ]}
          ],
          [{label: "拉黑原因", type: "textarea", key: "remark", prop: "remark"}]
        ],
        formData: data || {
          nickname: "",
          userName: "",
          phoneNumber: "",
          userSource: "",
          remark: "",
        },
        rules: {
          userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          phoneNumber: [{ required: true, message: '请输入电话', trigger: 'blur' }],
          remark: [{ required: true, message: '请输入拉黑原因', trigger: 'blur'}],
        }
      }, mod, this.poPupCallback)
    },
    /**
     * 新增
     */
    addUserInfo(){
      this.createTemplateData("新增黑名单用户", "add")
    },
    /**
     * 修改
     */
    updateUserInfo(data){
      this.createTemplateData("修改用户","update", data)
    },
    /**
     * 删除
     */
    delUserInfo(id){
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sendService(id, "del")
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    /**
     * 弹出层回调事件
     * 这里可能需要针对某些接口做数据预处理， 最后将数据发送给服务器
     * @param data
     * @param mode
     */
    poPupCallback(data, mode){
      this.sendService(data, mode)
    },
    /**
     * 搜索组件的回调
     * @param data {Object} 搜索的条件数据
     */
    searchCallBack(data){
      this.getBlackList(data, true)
    },

    /**
     * 向服务器发送请求，以及回调的状态处理
     * @param data
     * @param mode
     */
    sendService(data, mode){
      new Promise((resolve, reject)=>{
        switch (mode){
          case "add":
            this.axios.post(this.$api.publicUrl.BlackList, data).then(res =>{
              if(res.data.status === 200){
                resolve("添加成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
          case "update":
            this.axios.put(this.$api.publicUrl.BlackList, data).then(res =>{
              if(res.data.status === 200){
                resolve("修改成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
          case "del":
            this.axios.delete(this.$api.publicUrl.BlackList + "/" + data ).then(res =>{
              if(res.data.status === 200){
                resolve("删除成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
        }
      }).then(res =>{
        this.getBlackList()
        this.$message({
          message: res,
          type: 'success'
        })
      }).catch(err =>{
          this.$message.error(err);
      })
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.getBlackList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getBlackList()
    }
  }
}
</script>
<template>
  <fragment v-if="tableData">
    <h1 class="title">请输入查询条件</h1>
    <section class="search">
      <SearchCombination :data="{
        list: [
          [{ti: '智能匹配', type: 'input', key: 'search', class: 'min'}],
        ],
        senior: false
      }" @searchCallBack="searchCallBack"></SearchCombination>
    </section>
    <section class="control">
      <div class="con_ti"></div>
      <div class="btn">
        <el-button type="primary" @click="this.addUserInfo" size="small">新增</el-button>
      </div>
    </section>
    <section class="tabulation" v-if="tableData">
      <Tabulation :data="{
        columnName: [
          {name: '昵称', prop: 'nickname', type: 'text', needSort: false},
          {name: '用户姓名', prop: 'userName', type: 'text', needSort: false},
          {name: '电话', prop: 'phoneNumber', type: 'text', needSort: false},
          {name: '用户来源', prop: 'userSource', type: 'text', needSort: false},
          {name: '备注', prop: 'remark', type: 'text', needSort: false, style: {flex: 2}},
        ],
        rows: this.tableData,
        control: [
          {name: '修改', type: 'warning', method: this.updateUserInfo},
          {name: '删除', type: 'danger', method: this.delUserInfo}
        ]
      }"></Tabulation>
    </section>
    <section class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="this.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.total">
      </el-pagination>
    </section>
  </fragment>
  <fragment v-else>
    <img src="../../../assets/images/loading.gif" class="loading" height="100" width="100" alt="加载中"/>
  </fragment>
</template>
<style src="@/assets/css/componentStyle.css" scoped></style>


